import React, { useContext, useEffect } from 'react';
// import ItemBox from './components/pages/Homepage/IzharsonBanner';
import IzharsonBanner from './IzharsonBanner';
import Products from './Products';
import Banner from './banner2';
import NewArrival from './NewArrivals';
import EmailBanner from './EmailBanner';
import PremiumCategories from './PremiumCatogories';
import About from '../About/About';
import Testimonial from './Testimonial';
import MaximunDiscount from './MaxDiscount';
import TopCategory from './TopCategory';
import { useState } from 'react';

function Home() {

    return (
        <div>
            <div className=' col-md-12 col-xs-12 col-sm-12 nopadding'>
                <IzharsonBanner />
            </div>
            <div className='mt-1 col-md-12 col-xs-12 col-sm-12 nopadding'>
                <Products />
            </div>
            <div className='mt-4 col-md-12 col-xs-12 col-sm-12 nopadding'>
                <MaximunDiscount />
            </div>

            <div className='mt-2 col-md-12 col-xs-12 col-sm-12 nopadding'>
                <Banner />
            </div>
            <div className='mt-1 col-md-12 col-xs-12 col-sm-12 nopadding'>
                <NewArrival />
            </div>
            <div className='mt-2 col-md-12 col-xs-12 col-sm-12 nopadding'>
                <EmailBanner />
            </div>
            <div className='mt-2 col-md-12 col-xs-12 col-sm-12 nopadding'>
                <PremiumCategories />
            </div>
            <div className=' col-md-12 col-xs-12 col-sm-12 nopadding'>
                <TopCategory />
            </div>
            <div className=' col-md-12 col-xs-12 col-sm-12 nopadding'>
                <Testimonial />
            </div>
            <div className='mt-2 col-md-12 col-xs-12 col-sm-12 nopadding'>
                <About />
            </div>
        </div>
    )
}

export default Home;