import React, { useState, useEffect, useContext } from 'react';
import style from '../../styles/header.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Box, IconButton, InputBase } from '@mui/material';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ButtonIcon from '../OtherPerfumes/OtherPerfumes';
import MenuIcon from '@mui/icons-material/Menu';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Drawer } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { BASE_URL } from '../../config';
import axios from 'axios';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { UserContext } from '../../App'
import Search from './searchProd';
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const shapeStyles = { width: 30, height: 30, border: '1px solid lightgray' };
const shapeCircleStyles = { borderRadius: '50%' };
export const Icons = ({ children }) => {
    return (
        <Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles }}>
            <div className='badge-style'>
                {children}
            </div>
        </Box>
    )
}

export default function () {
    const [headerData, setHeaderData] = useState('')
    const [open, setOpen] = useState(false);
    const { item, setItem, setWishCount, wishCount } = useContext(UserContext);
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem("userdata"))
    const [profile, setprofile] = React.useState(10);
    const handleChange = (event) => {
        setprofile(event.target.value);
    };
    let machineId = localStorage.getItem('MachineId');

    const navigate = useNavigate();

    function Logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("userdata");
        navigate('/login')
    }

    useEffect(() => {
        featchHeaderData();
        fetchCount();
        FetchItemsAuth();
        FetchWishCounts();
        FetchWishlistUnauth();
    }, [])

    const FetchWishCounts = async () => {
        if (token) {
            axios.get(BASE_URL + '/api/get-wishlist', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(({ data }) => {
                setWishCount(data.data.length)
            })
        }
    }

    const FetchWishlistUnauth = async () => {
        if (!token) {
            const data = { "device_id": machineId }
            await axios.post(BASE_URL + '/api/get-wishlist-unauth', data).then(({ data }) => {
                setWishCount(data.data.length)
            })
        }
    }



    const fetchCount = async () => {
        if (!token) {
            const data = { "device_id": machineId }
            await axios.post(BASE_URL + '/api/get-cart-unauth', data).then(({ data }) => {
                setItem(data.data.cart.item)

            })
        }
    }

    const FetchItemsAuth = async () => {
        if (token) {
            axios.get(BASE_URL + '/api/get-cart', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(({ data }) => {
                setItem(data.data.cart.item)
            })
        }
    }

    const featchHeaderData = async () => {
        await axios.get(BASE_URL + '/api/header-settings').then(({ data }) => {
            setHeaderData(data.data);
        })
    }


    return (
        <div className={style.MobileView}>
            <div className={`flex ${style.MobileViewStyle}`}>

                <div className="pointer flex">
                    <LocationOnIcon sx={{ fontSize: 20 }} />
                    <div className={style.fontStyle} onClick={() => navigate('/my-orders')}>Track your order</div>
                </div>
                <div className={`${style.margin} ${style.fontStyle}`}>
                    {localStorage.getItem('token') ? (
                        <FormControl>
                            <Select
                                value={profile}
                                onChange={handleChange}
                                className={style.dropdown}
                                variant="filled"

                                input={<InputBase name="profile" id="profile-customized-select" />}
                            >
                                <MenuItem value={10} disabled sx={{ fontSize: 18 }} >
                                    {/* <ListItemIcon> */}
                                    <FontAwesomeIcon icon={faUser} />  {user.name}
                                    {/* </ListItemIcon> */}
                                </MenuItem>
                                <MenuItem value={20} sx={{ fontSize: 18 }} onClick={() => navigate('/dashboard')}>Dashboard</MenuItem>
                                <MenuItem value={30} sx={{ fontSize: 18 }} onClick={() => Logout()} >
                                    Logout</MenuItem>
                            </Select>
                        </FormControl>
                    ) : (
                        <div onClick={() => navigate("/login")} className={style.LoginfontStyle}>
                            Login / Signup
                        </div>
                    )}
                </div>
            </div>
            <div className={style.border}></div>

            <div className={`flex ${style.MobileViewStyle}`}>

                <Link to="/" className='pointer'>
                    <img src={BASE_URL + '/storage/' + headerData.header_logo} alt="IZHARSONS_logo" className={style.logo} />
                </Link>
                <div className='flex mt-3 kart-icon'>
                    <div className={style.margin} onClick={() => navigate('/wishlist')}>
                        <Badge color="primary" badgeContent={wishCount} className='pointer' >
                            <Icons>
                                <img src="/Icons/heart.svg" className={style.img} />
                            </Icons>
                        </Badge>
                    </div>
                    <div className={style.margin} onClick={() => navigate('/cart')}>
                        <Badge color="primary" badgeContent={item} className='pointer'>
                            <Icons>
                                <img src="/Icons/cart-logo.svg" className={style.img} />
                            </Icons>
                        </Badge>
                    </div>
                </div>
            </div>
            <div className={style.border}></div>
            <div className='mt-2'></div>

            <div className={`flex ${style.MobileViewStyle}`}>
                <ButtonIcon />
                <div className={style.drawerButton} onClick={() => setOpen(!open)}>
                    <MenuIcon sx={{ fontSize: 25 }} />
                </div>
            </div>
            <div className={style.border}></div>
            <div>
                {/* <div>
                    <input placeholder="Search Product..." className={style.SearchInput} />
                    <button className={style.searchButton}>
                        <img src="/Icons/SearchIcon.png" className={style.SearchIcon} />
                    </button>
                </div> */}
                <div className='ml-15'>
                    <Search />
                </div>
            </div>

            <Drawer onClose={() => setOpen(false)} open={open} variant="persistent" anchor='right'>

                <div className={`margin-left-right10 ${style.padding15}`} >
                    <ChevronLeftIcon onClick={() => setOpen(false)} />
                </div>
                <div className={style.padding20}>
                    <div className="margin-left-right10">
                        <Link className={`${style.margin} ${style.menuFont}`} to="/" onClick={() => setOpen(false)}><div >Home</div></Link>
                    </div>
                    <div className={style.border}></div>
                    <div className="margin-left-right10">
                        <Link className={`${style.margin} ${style.menuFont}`} to="/About" onClick={() => setOpen(false)}>About Us</Link>
                    </div>
                    <div className={style.border}></div>
                    <div className="margin-left-right10">
                        <Link to="/our-products" state={{ data: 0 }} className={`${style.margin} ${style.menuFont}`} onClick={() => setOpen(false)}>Our Products</Link>
                    </div>
                    <div className={style.border}></div>
                    <div className="margin-left-right10">
                        <Link className={`${style.margin} ${style.menuFont}`} to="/feedback" onClick={() => setOpen(false)}>Feedback</Link>
                    </div>
                    <div className={style.border}></div>
                    <div className="margin-left-right10">
                        <Link className={`${style.margin} ${style.menuFont}`} to="/Contact">Contact Us</Link>
                    </div>
                </div>
            </Drawer>


        </div>
    )
}