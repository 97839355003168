import React, { useState, useEffect, useContext } from 'react';
import style from '../../styles/header.module.css';
import { Badge, Box, InputBase } from '@mui/material';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../config';
import axios from 'axios';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { UserContext } from '../../App';
import Search from './searchProd';
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import Swal from 'sweetalert2';


const shapeStyles = { width: '50px', height: '50px', border: '1px solid #E9E9E9' };
const shapeCircleStyles = { borderRadius: '50%' };

export const Icons = ({ children }) => {
    return (
        <Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles }}>
            <div className='badge-style'>
                {children}
            </div>
        </Box>
    )
}


function SearchHeader() {

    const navigate = useNavigate();
    const [logoutModal, setLogoutModal] = useState(false);

    function Logout() {
        Swal.fire({
            // icon:'success',
            text:'Are you sure you want to logout',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem("token");
                localStorage.removeItem("userdata");
                navigate('/')
              console.log('Custom action executed.');
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              console.log('Modal dismissed or Cancel button clicked.');
            }
          });
        }

    const [headerData, setHeaderData] = useState('')
    const { item, setItem, setWishCount, wishCount } = useContext(UserContext);
    const token = localStorage.getItem('token');
    let machineId = localStorage.getItem('MachineId');
    const [product, setProduct] = useState();
    const user = JSON.parse(localStorage.getItem("userdata"))
    const [profile, setprofile] = React.useState(10);
    const handleChange = (event) => {
        setprofile(event.target.value);
    };

    useEffect(() => {
        featchHeaderData();
        fetchCount();
        FetchItemsAuth();
        FetchWishCounts();
        FetchWishlistUnauth();
        fetchProduct()
    }, [])

    const fetchProduct = async () => {
        await axios.get(BASE_URL + '/api/manage-product-with-category/0').then(({ data }) => {
            setProduct(data.data);
            // console.log(data.data)
        })
    }


    const FetchWishCounts = async () => {
        if (token) {
            axios.get(BASE_URL + '/api/get-wishlist', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(({ data }) => {
                setWishCount(data.data.length)
                // console.log('hyd', data.data)

            })
        }
    }

    const FetchWishlistUnauth = async () => {
        if (!token) {
            const data = { "device_id": machineId }
            await axios.post(BASE_URL + '/api/get-wishlist-unauth', data).then(({ data }) => {
                setWishCount(data.data.length)
            })
        }
    }

    const featchHeaderData = async () => {
        await axios.get(BASE_URL + '/api/header-settings').then(({ data }) => {
            setHeaderData(data.data);
        })
    }

    const fetchCount = async () => {
        if (!token) {
            const data = { "device_id": machineId }
            await axios.post(BASE_URL + '/api/get-cart-unauth', data).then(({ data }) => {
                setItem(data.data.cart.item)

            })
        }
    }

    const FetchItemsAuth = async () => {
        if (token) {
            axios.get(BASE_URL + '/api/get-cart', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(({ data }) => {
                setItem(data.data.cart.item)
            })
        }
    }



    return (
        <div className={style.DesktopView}>
            <div className={`col-md-12 col-xs-12 col-sm-12 ${style.seachContainer}`}>
                <div className='col-md-2 col-sm-4'>
                    <Link to="/" className='pointer'>
                        <img src={BASE_URL + '/storage/' + headerData.header_logo} alt="IZHARSONS_logo" className={`hover ${style.logo}`} />
                    </Link>
                </div>
                <div className={`col-md-10 col-sm-8 ${style.rightPanel} ${style.marginTop}`}>
                    <div className={style.margin}>
                        <Search />
                    </div>
                    <div className={`${style.margin} ${style.marginTop}`}>
                        {localStorage.getItem('token') ? (
                            <FormControl>

                                <Select
                                    value={profile}
                                    onChange={handleChange}
                                    className={style.dropdown}
                                    sx={{fontSize:18}}
                                    variant="filled"

                                    input={<InputBase name="profile" id="profile-customized-select" />}
                                >
                                    <MenuItem selected value={10} sx={{ fontSize: 18 }} >
                                      {user && user.image?(
                                        <img className='header-user' src={BASE_URL+'/storage/'+user.image} />
                                      ):(
                                    <FontAwesomeIcon icon={faUser}  style={{marginTop:"-5px", marginRight:"10px"}}/>
                                      )}    {user.name}
                                    </MenuItem>
                                    {/* <MenuItem selected value={10} sx={{ fontSize: 18 }} >
                                      {user && user.image?(
                                        <img className='header-user' src={BASE_URL+'/storage/'+user.image} />
                                      ):(
                                    <FontAwesomeIcon icon={faUser} />
                                      )}    {user.name}
                                    </MenuItem> */}
                                    <MenuItem value={20} sx={{ fontSize: 18 }} onClick={() => navigate('/dashboard')}>Dashboard</MenuItem>
                                    <MenuItem value={30} sx={{ fontSize: 18 }} onClick={() => Logout()} >
                                        Logout</MenuItem>
                                </Select>
                            </FormControl>
                        ) : (
                            <div onClick={() => navigate("/login")} className={`hover ${style.LoginfontStyle}`}>
                                Login / Signup
                            </div>
                        )}
                    </div>
                    <div className={`hover ${style.margin}`}>
                        {/* <Link to="/wishList"> */}
                            <Badge color="primary" overlap="circular" badgeContent={<div className={style.badge}>{wishCount}</div>} className='pointer' onClick={() => navigate('/wishlist')}>
                                <Icons>
                                    <img src="/Icons/heart.svg" className={style.img} />
                                </Icons>
                            </Badge>
                        {/* </Link> */}
                    </div>
                    <div className={`hover ${style.margin}`}>
                        <Link to="/cart">
                            <Badge color="primary" overlap="circular" badgeContent={<div className={style.badge}>{item}</div>} className='pointer'>
                                <Icons>
                                    <img src="/Icons/cart-logo.svg" className={style.img} />
                                </Icons>
                            </Badge>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchHeader;